<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/app/007.jpg">
                </div>
				<div class="con_top_right">
					<h2>APP安全能力第三方评估报告</h2>
					<p class="new_tit">App、安全评估报告、第三方安全评估机构、App上架、小程序上架、应用商店上架、全国互联网安全管理平台</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">
                            <!-- 4900起   （具体价格请在线咨询） -->
                            20000元（包过价）
                        </span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                针对《具有舆论属性或社会动员能力的互联网信息服务安全评估规定》，要求通过全国互联网安全服务平台提交一份安全评估报告，万方为客户提供互联网信息服务安全评估服务，出具安全评估报告。
                            </p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
        <!-- 应用场景 -->
			<h3>APP安全能力第三方评估报告</h3>
				<div class="scene">
                    <h3>互联网信息服务适用场景</h3>
                    <div class="scene_content">
                        <div class="scene_left">
                            <ul>
                                <li>1. 具有舆论属性或社会动员能力的信息服务上线，或者信息服务增设相关功能。</li>
                                <li>2. 发生违法有害信息传播扩散，表明已有安全措施难以有效防控网络安全风险。</li>
                                <li>3. 用户规模显著增加，导致信息服务的舆论属性或者社会动员能力发生重大变化。</li>
                                <li>4. 使用新技术新应用，使信息服务的功能属性、技术实现方式、基础资源配置等发生重大变更，导致舆论属性或者社会动员能力发生重大变化。</li>
                                <li>5. 地市级以上网信部门或者公安机关书面通知需要进行安全评估的其他情形。</li>
                            </ul>
                        </div>
                        <div class="scene_right">
                            <img src="@/assets/images/app/sucai1.jpg" alt="">
                        </div>
                    </div>
                </div>

                <div class="business">
                    <h3>业务开展的必要性</h3>
                    <div class="business_content">
                        <p>1.2018年，国家网信办发布的《具有舆论属性或社会动员能力的互联网信息服务安全评估规定》:“针对具有舆论属性或社会动员能力的互联网信息服务的主体，都需要提供一份《安全评估报告》。”</p>
                        <p>简单来说，无论是网站、App、小程序、公众号、博客等，都需要提交《安全评估报告》。</p>
                        <div class="business_img">
                            <img src="@/assets/images/app/sucai-3.jpg" alt="">
                        </div>
                        <p>2.各大应用市场响应号召需要开发者提供「安全评估报告」，没有提供该项材料的开发者可能面临着应用被下架或者限制外显等惩罚。</p>
                        <div class="business_lists">
                            <ul>
                               <li>VIVO应用商店</li>
                               <li>小米应用商店</li>
                               <li>OPPO应用商店</li>
                               <li>华为应用商店 </li>
                               <li>豌豆荚</li>
                               <li>阿里应用分发开放平台 </li>
                               <li>360移动开放平台 </li>
                               <li>百度手机助手</li>
                               <li>腾讯应用宝</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="profit">
                    <h3>客户收益</h3>
                    <div class="profit_content">
                        <ul>
                            <li>
                                <div class="li_img">
                                    <img src="@/assets/images/app/sucai5.jpg" alt="">
                                </div>
                                <p>App在各大应用市场合规上线</p>
                            </li>
                            <li>
                                <div class="li_img">
                                    <img src="@/assets/images/app/sucai6.jpg" alt="">
                                </div>
                                <p>满足全国互联网安全管理平台提交报告的要求。</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="product">
                    <h3>产品详细</h3>
                    <div class="product_img">
                        <img src="@/assets/images/app/security.jpg" alt="">
                    </div>
                </div>

                <div class="advantage">
                    <h3>我们的优势</h3>
                    <div class="advantage_content">
                        <div class="content">
                            <div class="content_left">
                                <ul>
                                    <li>国家信息安全服务资质(安全工程类)</li>
                                    <li>国家信息安全风险评估资质</li>
                                    <li>国家通信网络安全服务风险评估资质</li>
                                    <li>国家信息安全应急处理服务资质</li>
                                    <li>国家信息系统安全集成服务资质</li>
                                    <li>国家信息安全漏洞库支持单位</li>
                                    <li>国际信息安全管理体系ISO 27001认证</li>
                                    <li>国际质量管理体系ISO9001认证</li>
                                    <li>国际环境管理体系ISO 14001认证</li>
                                    <li>高新技术企业</li>
                                    <li>软件企业</li>
                                    <li>企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="content_right">
                                <img src="@/assets/images/app/qualifications.jpg" alt="">
                            </div>
                        </div>
                        <div class="content_bottom">
                            <ul>
                                <li>1. 安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                                <li>2. 专注网络信息安全领域10年以上，熟悉监管部门对政策法规的具体落地要求。</li>
                                <li>3. 公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质。</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="case">
                    <h3>典型案例</h3>
                    <div class="case_lis">
                        <ul>
                            <li>1. 北京房某区房某通系统具有舆论属性或社会动员能力的互联网信息服务安全评估项目</li>
                            <li>2. 广州某消防系统互联网新技术新业务安全评估项目</li>
                            <li>3. 深圳某十商城网络防伪技术有限公司具有舆论属性或社会动员能力的互联网信息服务安全评估项目</li>
                        </ul>
                    </div>
                </div>


				

			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/beijing">
                                            <img src="@/assets/images/record/beijing.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>北京市增值电信业务</p>
                                        <p>1.服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/jiangxi">
                                            <img src="@/assets/images/record/jiangxi.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>江西省增值电信业务</p>
                                        <p>1.服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
    padding-left: 35px;
    h3{
        margin-top: 100px;
        font-size: 28px;
        text-align: center;
        font-weight: bold;
    }
}

.scene{
    margin-top: 104px;
    h3{
        font-size: 25px;
    }
    .scene_content{
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .scene_left{
            width: 434px;
            ul{
                li{
                    font-size: 20px;
                    line-height: 20px;
                    margin-top: 5px;
                }
            }
        }
        .scene_right{
            width: 320px;
            height: 320px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.business{
     margin-top: 90px;
    h3{
        font-size: 25px;
    }
    .business_content{
        margin-top:75px;
        p{
            font-size:20px;
            line-height: 22px;
        }
        .business_img{
            width: 708px;
            height: 494px;
            margin: 50px auto;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .business_lists{
            margin-top: 80px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                li{
                    font-size: 17px;
                    line-height: 34px;
                    padding: 0 10px;
                    margin-right: 50px;
                    border: 1px solid #797979;
                    margin-bottom: 26px;
                }
            }
        }

    }
}
.profit{
     margin-top: 64px;
    h3{
        font-size: 25px;
    }
    .profit_content{
        margin-top: 66px;
        ul{
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .li_img{
                    width: 110px;
                    height: 106px;
                    margin-bottom: 45px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    display: block;
                    width: 258px;
                    text-align: center;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
            :nth-child(2){
                p{
                    width: 230px;
                }
            }
        }
    }
}
.product{
      margin-top: 30px;
    h3{
        font-size: 25px;
    }
    .product_img{
        width: 850px;
        height: 524px;
        margin: 45px auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.advantage{
    margin-top: 30px;
    h3{
        font-size: 25px;
    }
    .advantage_content{
        margin-top: 74px;
        .content{
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .content_left{
                ul{
                    li{
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }
            .content_right{
                width: 313px;
                height: 296px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content_bottom{
            margin: 34px 70px;
            ul{
                li{
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }
    }
}
.case{
    margin-top: 90px;
    h3{
         font-size: 25px;
    }
    .case_lis{
        ul{
            padding: 56px 40px;
            li{
                font-size: 20px;
                line-height: 22px;
            }
        }
    }
}



.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;

	line-height: 18px;
	font-size:16px;
    padding:5px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 16px;
        }
    }
}
.box_ti{
   margin-top:90px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







